/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))

        var swiperhome = new Swiper('#swiper-home', {
            speed: 1000,
            spaceBetween: 0,
            paginationClickable: true,
            autoplay:
                {
                    delay: 4000,
                },
            loop: true,
            auto: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><span class="nbr">0' + [index + 1] + '</span></span>'
                }
            },
            slidesPerView: 1
        });


    }
} 
